import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  checkIfMural,
  useVariantPrice,
  formatPrice,
  translateString,
} from '../utils';
import { StoreContext } from '../context/StoreContext';

const StyledPrice = styled.p``;

const Price = ({ variant, locale, ...props }) => {
  const { currency } = useContext(StoreContext);
  const variantPrice = useVariantPrice(variant);

  const isMural = checkIfMural(variant.sku);

  return (
    variantPrice && (
      <StyledPrice {...props}>
        <span>
          {formatPrice({
            amount:
              variantPrice.amount *
              (isMural ? (currency === 'USD' ? 1 : 10) : 1),
            currencyCode: variantPrice.currencyCode,
            locale: locale,
          })}
        </span>{' '}
        {isMural
          ? currency === 'USD'
            ? translateString('product.perFt', locale)
            : translateString('product.perM', locale)
          : translateString('product.perRoll', locale)}
        {isMural && <sup>2</sup>}
      </StyledPrice>
    )
  );
};

export default Price;
